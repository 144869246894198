.testimonials-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.testimonial{
    display: flex;
    flex-direction: column;
    padding: 10px;
    padding-bottom: 60px;
    margin: 10px;
    border: 1px solid white;
    border-radius: 4px;
    max-width: 450px;
    max-height: 300px;
    /* background:linear-gradient(#000,green,#3C873A); */
  filter: drop-shadow(10px 10px 11px #000);
position: relative;
}
.testimonial-review{
    color: rgb(240,240,240);
    word-spacing: 1.5px;
    line-height: 1.4rem;
}
.testimonial-top{
    display: flex;
    width: 100%;
    padding-bottom: 10px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.testimonial-top-avatar{
    width: 55px;
    max-height: 53px;
    display: flex;
    border-radius: 50%;
    overflow: hidden;
}
.testimonial-top-rating{
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-left: 10px;
}
.testimonial-top-rating>p{
    font-size: 17px;
    font-weight: 600;
}
.location-indicator{
    display: flex;
    align-items: center;
    font-size: 12px !important;
    font-weight: 400 !important;
    color: #999;
    padding-bottom: 2px;
    position: relative;
    left: -7px;
}
.testimonial-top-rating>div>*{
    color: goldenrod;
}
.tick-icon{
font-size: 2rem !important;
color: green;
position: relative;
right: -5px;
}
.testimonial-logo-container{
    display: flex;
    width: 25%;
    max-height: 50px;
    justify-content: flex-end;
    overflow: hidden;
    align-self: flex-end;
    border-radius: 25px 0 0 25px;
    margin-top: 4px;
    position: absolute;
    right: 0px;
    bottom: 10px;
}