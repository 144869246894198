.fleetTab-container {
  box-sizing: border-box;
}
.tab-content-container-main {
  display: flex;
  align-items: center;
  background: linear-gradient(
    to bottom,
    #e9e9e9 0%,
    #e9e9e9 -10%,
    transparent 70%
  );
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 0 14px;
  margin-bottom: 7%;
  padding-top: 50px;
  box-sizing: border-box;
  justify-content: center;
}
.tabContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 511px;
  justify-content: space-around;
  max-width: 1230px;
  margin: 0 10px;
}
.tabContent-img {
  flex: 0.435;
  display: flex;
  border-radius: 10px;
}
.tabContent-img > img {
  /* max-width: 560px; */
  width: 100%;
  height: auto;
  object-fit: contain;
  filter: drop-shadow(10px 20px 11px #000);
}
.tabContent:hover > .tabContent-img > img {
  transform: translateX(87px);
}

.tabContent-info {
  display: flex;
  flex-direction: column;
  flex: 0.37;
  padding: 0 5px;
}
.tabContent-info > :first-child {
  margin: 0 0 17px 0;
  /* background:linear-gradient(#000,#0078C4,#61DBFB); */
  align-self: flex-start;
  border-radius: 4px;
  filter: drop-shadow(1px 9px 2.1px #111);
  letter-spacing: 2.4px;
}
.tabContent-info > :nth-child(2) {
  color: #333;
  font-size: 17px;
}

.tabContent-info > p {
  color: white;
  margin: 15px 0;
  line-height: 1.6rem;
  letter-spacing: 0.7px;
  word-spacing: 2px;
}

.tabContent-img > img {
  width: 100%;
  max-height: 400px;
  transition: 600ms ease;
}
.tab-icons {
  display: flex;
  padding: 10px 10px 10px 0;
}
.tab-icons > * {
  height: 21px;
  margin-right: 15px;
}

/* tablinks css */
.fleetTab-container {
  margin-top: 10%;
}
.fleetTab {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  margin: 0 11px;
  overflow-x: scroll;
  box-sizing: border-box;
  /* width: 91%; */
  scroll-behavior: smooth;
}
.fleetTab::-webkit-scrollbar {
  width: 0px;
  height: 0;
}

.fleetTab-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin: 0 15px;
  padding: 0 18px;
  box-sizing: border-box;
  padding-top:40px;
}
.fleetTab-link-icon-container {
  background: transparent;
  transition: 300ms ease-in-out;
  width: 85px;
  height: 80px;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.fleetTab-link > p {
  color: #999;
  font-size: 1.11rem;
  font-weight: 500;
  margin-top: 11px;
  padding-bottom: 16px;
  width: 100%;
  text-align: center;
  white-space: nowrap;
}

.icon2,
.icon1 {
  width: 100%;
  height: 100%;
  margin: 0 3px;
  object-fit: contain;
  transition: 250ms ease-in-out;
}
.fleetTab-link:hover > .fleetTab-link-icon-container {
  border: 2px solid white;
  border-radius: 50px;
}
.fleetTab-link:hover > .fleetTab-link-icon-container > .icon2 {
  transform: scale(1.2);
}
.fleetTab-link:hover > .fleetTab-link-icon-container > .icon1 {
  /* display:none !important; */
  width: 0;
}

.tabContent {
  display: none;
}
.active {
  /* background-color: white; */
}
.tab-link-icon-container {
  display: flex;
  align-items: center;
  /* padding: 0 14px; */
  width: 100%;
  box-sizing: border-box;
  justify-content: center;
}
.prev-button,
.next-button {
  border: 2px solid white;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  padding: 1px;
  cursor: pointer;
}
.prev-button:hover,
.next-button:hover {
  color: gray;
  border-color: gray;
}
.tabContent-button {
  padding: 11px 20px;
  font-size: 15px;
  background-color: transparent;
  border: 2px solid white;
  border-radius: 4px;
  color: white;
  letter-spacing: 2px;
  font-weight: 500px;
  cursor: pointer;
  transition: 250ms ease;
  margin: 10px 0 0 0;
  width: 93%;
}
.tabContent-button:hover {
  color: orange;
  border-color: orange;
  border-radius: 11px;
  filter: drop-shadow(2px 5px 2px #000);
}
.fleetTab-content-container > :first-child > .tabContent-info > button {
  display: none;
}
.fleetTab-content-container > :first-child > .tabContent-img > img {
  border: 6px solid rgb(250, 150, 40);
  border-radius: 100%;
  width: 365px;
  max-height: 341px;
  filter: drop-shadow(7px 9px 4px #000);
  border-style: double;
  object-fit: contain;
}
@media (max-width: 1250px) {
  .tabContent-info > p {
    font-size: 0.91rem;
  }
}
@media (min-width: 1170px) {
  .fleetTab-content-container:hover > :first-child > .tabContent-img > img {
    border-right: none;
    border-radius: 50px 4px 4px 50px;
  }
  .fleetTab-content-container:hover > :first-child > .tabContent-info {
    box-sizing: border-box;
    border: 6px solid rgb(250, 150, 40);
    border-style: double;
    border-radius: 4px 55px 55px 4px;
    border-left: none;
    padding: 12px 0 12px 0;
    filter: drop-shadow(7px 9px 4px #111);
    transform: translateX(-60px);
    flex: 0.6;
  }
  .fleetTab-content-container:hover > :first-child > .tabContent-info > h1 {
    filter: none;
  }
  .fleetTab-content-container > :first-child > .tabContent-info {
    flex: 0.6;
    width: 100%;
    border-left: none;
    transition: 600ms ease;
  }
}

@media (max-width: 1000px) {
  .tabContent {
    box-sizing: border-box;
    flex-direction: column;
    height: auto;
    zoom: 0.9;
  }
  .tabContent-info {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: left;
  }
  .tabContent-info > :nth-child(2) {
    color: #999;
  }
  .tabContent-info > :first-child {
    align-self: center;
    margin: 40px 0;
  }
  .fleetTab {
    margin: 0 5px;
  }
  #tabcontent-bottom-buttom1,
  #tabcontent-bottom-buttom2 {
    display: none;
  }
  .fleetTab-link {
    margin: 0 5px;
    padding: 0 1px;
    padding-top: 40px;
  }
  .fleetTab-link-icon-container {
    width: 55px;
    height: 50px;
  }
  .fleetTab-link > p {
    font-size: 14px;
    margin-top: 7px;
  }
  .tabContent-img > img {
    max-width:360px;
    margin: 0;
    padding: 0;
  }
  .tabContent:hover > .tabContent-img > img {
    transform: translateX(0px);
  }
  .fleetTab-link:hover > .fleetTab-link-icon-container {
    border: 1px solid white;
    border-radius: 50%;
  }
  .tabContent-info > p {
    margin: 15px 11px;
    line-height: 1.6rem;
    letter-spacing: 0.7px;
    word-spacing: 2px;
    padding: 0 4px;
  }
}
@media (max-width: 375px) {
  .fleetTab-link-icon-container {
    width: 45px;
    height: 40px;
    border-radius: 0 !important;
    border: none !important;
  }

  .tabContent-img > img {
    max-width:340px;
    margin: 0;
    padding: 0;
  }
  .fleetTab-link > p {
    font-size: 12px;
    margin-top: 7px;
  }
  .prev-button,
  .next-button {
    zoom: 0.77;
  }
}
@media (max-width: 290px) {
  .tabContent {
    zoom: 0.7;
  }

}