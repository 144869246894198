*{
  margin:0;
  -webkit-tap-highlight-color: transparent;

}
a{
  text-decoration: none !important;
  color: inherit;
}
html{
  scroll-behavior:smooth;
  /* overflow: hidden; */
}
body{
  color: white;
  transition: 250ms ease;
  background-color:#000;
  background-image: url("https://www.transparenttextures.com/patterns/brick-wall-dark.png");
  
}
::-webkit-scrollbar{
  width: 14px;
  background-color: #ccc;
}
::-webkit-scrollbar-thumb{
  border-radius: 4px;
  background-color: #333;
  transition: 250ms ease;
}::-webkit-scrollbar-thumb:hover {
  background: #777;
}
::-webkit-scrollbar-track{
  box-shadow: inset 0 0 5px grey;
}

.App{
  position:relative;
  top: 40px;
}

.background-container{
  z-index: -10000;
  width: 100%;
  height: 100vh;
  background-color:#000 ;
  background-image: url("https://www.transparenttextures.com/patterns/brick-wall-dark.png");
  position: fixed;
  color: white;
}
.header{
  background-color: #222;
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1px 15px;
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%;
  box-sizing: border-box;
}
.header-nav-link-container{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 24%;
  margin-left: 21px;
}
.header-nav-link-container>*{
  letter-spacing: 1.4px;
  font-weight: 500;
  font-size: 15px;
  transition: 250ms ease;
  border: 1px solid #222;
  padding: 2px 5px;
  border-radius: 20px;
  min-width: 50px;
  text-align: center;
  margin-left: 11px;
}
.header-nav-link-container>*:hover{
  color: orange;
  border-radius: 15px;
  border-color: orange;
}

.header-social-icons{
  font-size: 1.9rem !important;
  margin:0 10px ;
  cursor: pointer;
  transition:250ms ease !important;
}
.header-social-icons:hover{
  transform: scale(1.11);
}
#name-heading{
 letter-spacing: 1px;
}
/* Slider css */
.carousel-slider{
  /* max-height: 550px !important; */
  overflow: hidden !important;
 
}
.img-slider-container>img{
  width: 100%;
  height: auto;
  object-fit: cover;
  -webkit-mask-image: linear-gradient(180deg,#000,transparent);
}

.footer{
  background-color:#333 ;
  background-image: url("https://www.transparenttextures.com/patterns/brick-wall.png");
  padding: 85px 50px 145px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #111;
  font-weight: 500;
  filter: drop-shadow(10px 0px 10px #000);
  z-index: 4;
}
.footer-social-icons{
  color: #999;
  font-size: 3.5rem !important;
  margin:0 25px 10px;
  transition: 250ms ease-in-out !important;
  cursor: pointer;
  filter: drop-shadow(2px 5px 4px #333);

}
.footer-social-icons:hover{
  transform: scale(1.21);
  color: white;
}
.footer>p{
  margin:10px 0px 0 11px;
  letter-spacing: 1.1px;
  word-spacing: 2px;
  filter: drop-shadow(1px 4px 4px #000);
  color: #999;
  white-space: nowrap;
}
.footer-arrow-up{
  background-color:#333 ;
  background-image: url("https://www.transparenttextures.com/patterns/brick-wall.png");
  padding: 5px 8px;
  border-radius: 50%;
  color: whitesmoke;
  transition: 250ms ease-in-out;
  cursor: pointer;
  position: relative;
  top: -124px;
  z-index: 5;
}
.footer-arrow-up:hover{
  color: #111;

}


#expandicon{
  font-size: 3rem;
  z-index: 5 !important;
}
@media (max-width:1000px){
  .header-nav-link-container{
    display: none;
  }
}
@media (max-width:700px){
  .header{
    height: 31px;
    padding: 1px 7px;
  }
  .header-nav-link-container{
    display: none;
  }
  .header-social-icons{
    font-size: 1.5rem !important;
    margin:0 4px ;
  }
  #name-heading{
    font-size: 1rem;
  }
}