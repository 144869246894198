.contact{
    padding: 10px;
    background-color: #000000;
    background-image: url("https://www.transparenttextures.com/patterns/light-honeycomb-dark.png");
    position: relative;
    box-sizing: border-box;
    min-height: 400px;
}
.contact>:nth-child(6){
    align-items: unset;
}

.contact-input-container{
    display: flex;
    max-width: 600px;
    width: 100%;
    justify-content: space-between;
    margin: 21px 0;
    align-items: center;
    display: none;
}
.contact-input-container>h4{
    width: 20%;
}
.contact-input-container>input:focus{
    background: #9999;
}
.contact-input-container>input{
    width: 80%;
    height: 55px;
    font-size: 18px;
    background: #7777;
    border: none;
    outline: none;
    color: white;
    padding: 2px 10px;
    box-sizing: border-box;
    z-index: 3;

}
#message-field:focus{
    background-color: #9999;
}
#message-field{
    background-color: #7777;
    border: none;
    outline: none;
    color: white;
    font-size: 17px;
    padding: 10px;
    box-sizing: border-box;
    width: 80%;
    z-index: 3;
}
.required{
    color: goldenrod;
    margin-left: 4px;
    font-weight: lighter;
    font-size: 19px;
}
.glow-blanket>:nth-child(even):hover{
    background: rgba(200,10,100,0.27);
    box-shadow: 10px 10px 80px 121px rgba(200,10,100,0.25);
}
.glow-blanket>:nth-child(3n):hover{
    background: rgba(40,120,240,0.27);
    box-shadow: 10px 10px 80px 121px rgba(40,120,240,0.25);
}
.glow-blanket{
    width: 99%;
    z-index: 0;
    position: absolute;
    height: 95%;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
}
.glow-box:hover{
    background: rgba(250,250,0,0.27);
    box-shadow: 10px 10px 80px 121px rgba(250,250,0,0.25);
}
.glow-box{
    height: 19px;
    width:19px;
    background-color:transparent;
    border-radius: 50%;
    transition: 0.51s ease-in-out;
}
.contact>:last-child{
    justify-content: flex-end;
}
.contact-input-container>button{
    z-index: 3;
    width: auto;
}
@media (max-width: 1000px) {
    .contact{
        padding: 10px;
        justify-content: center;
        align-items: center;
        align-content: center;
        padding-bottom: 31px;
    }
    .contact-input-container{
        max-width: unset;
        width: 100%;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        flex-wrap: wrap;
    }
    .contact-input-container>input{
        width: 100%;}
    #message-field{
        width: 100%;
    }
    .glow-blanket{
        display: none;
    }
  }