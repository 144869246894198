.work{
    display: flex;
    flex-direction: column;
    position: relative;
    top: -200px;
}
.work-heading{
    border: 1px solid white;
    border-radius: 10px;
    text-transform: uppercase;
    padding:10px 20px;
    box-sizing: border-box;
    margin: 5%;
    margin-top: -35px;
    align-self: flex-start;
    box-shadow: 1px 4px 10px #000;
    filter: drop-shadow(1px 10px 3px #000);
}
.work-list{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 100%;
}
.flip-card{
    background-color: transparent;
    margin: 5.7% 10px;
    perspective: 1000px; 
    height: 351px;
    width: 571px;
    z-index: 100!important;
    background-color: transparent;
    filter: drop-shadow(1px 4px 10px #000);
}
  
  /* This container is needed to position the front and back side */
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform .6s;
    transform-style: preserve-3d;
    box-shadow: 1px 6px 20px 0 rgba(0,0,0,.9);
    border-radius: 9px;
  }
  
  /* Do an horizontal flip when you move the mouse over the flip box container */
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  /* Position the front and back side */
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;

    border-radius: 9px;
    background-color: #bbb;
    overflow: hidden;
  }
  
  .flip-card-back {
    background-color: transparent;
    color: white;
    transform: rotateY(180deg);
    border-radius: 9px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 2px solid #000;
    box-shadow: 1px 2px 0 10px #777;
  }
  .flip-card-front{
      display: flex;
  }
  .flip-card-front>img{
        width: 100%;
        height: auto;
  }

.demoLink{
    font-size: large;
    margin: 15px;
    padding: 7px;
    border: 2px solid #999;
    border-radius: 9px;
    color: #fff;
    text-decoration: none;
    font-weight: bolder;
    transition: 250ms ease;
    box-sizing: border-box;
}
.demoLink:hover{
  border-color:orange ;
  color: orange;
}

.flip-card-back>h1{
  filter: drop-shadow(1px 2px 1px #999);
 }   
.flip-card-back>p{    
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  text-align: center;
  color: #fff;
  margin: 0;
  line-height: 2rem;
  word-spacing: 4px;
  letter-spacing: 1px;
  font-weight: 450;
  margin-left: 15px;
  margin-right: 15px;
  text-shadow: 0 0 4px #000;
  filter: drop-shadow(1px 1px 40px #000);

}


@media (max-width:1199px){
  .work-heading{
    align-self: center;
  }

}
@media (max-width:700px){
  .work{
    top: 0px;
    margin-bottom: 150px;

  }
  .flip-card{
    margin: 11% 0;
    height: 270px;
    width: 450px;
}
  .work-heading{
    padding:10px 20px;
    align-self: center;
    font-size: 0.57rem;
}
.flip-card-back {
  border: 1px solid #000;
  box-shadow: 1px 1px 0 4px #777;
}
.flip-card-back>h1{
  font-size: 1.5rem;
  filter: drop-shadow(1px 2px 1px #999);
  margin-top: 5px;
 }   
.flip-card-back>p{    
  line-height: 1.4rem;
  word-spacing: 3px;
  letter-spacing: 1.4px;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 12px;
}
.demoLink{
  font-size: 14px;
}

}
@media (max-width:421px){
  .flip-card{
    margin: 5.7% 0;
    height: 215px;
    width: 320px;
}
.flip-card-back>h1{
  font-size: 1.2rem;
  filter: drop-shadow(1px 2px 1px #999);
  margin-top: 0px;
 }   
.flip-card-back>p{    
  line-height: 1rem;
  word-spacing: 2px;
  letter-spacing: 1.1px;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 11px;
}
.demoLink{
  font-size: 12px;
  margin-top: 1px;
}
}
@media (max-width:340px){
  .flip-card{
    height: 190px;
    width: 300px;
}
}
@media (max-width:290px){
  .flip-card{
    margin: 5.7% 0;
    height: 170px;
    width: 265px;
}
.flip-card-back {
  zoom: 0.8;
}

}
